.user-header {
  margin-top: 12px;
  margin-bottom: 10px;
  min-height: 150px;
}

.user-header.profile {
  position: relative;
}

.user-header.profile .brand {
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  align-items: center;
}

.user-header.profile .brand img {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-left: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
}

.user-header-about {
  padding: 12px 10px 0;
  border-top: 1px solid #e8e8e8;
}

.user-header h1 {
  font: 16px/1.2em "Titillium Web", sans-serif;
  margin: 0 0 7px;
}

.user-header-data {
  text-align: center;
  margin-bottom: 0;
}

.user-header h2 {
  font: 700 13px/1.2em "Titillium Web", sans-serif;
  margin-bottom: 0;
}

.user-header-contact .dealer-name {
  text-transform: uppercase;
}

.user-header-contact .job-description {
  margin-bottom: 5px;
}

.user-header-photo {
  display: flex;
  justify-content: center;
}

.user-header-photo span {
  margin-bottom: 7px;
}

.user-header-data .ant-btn-circle {
  border: none;
  background-color: transparent;
  font-size: 28px;
  display: none;
  align-items: center;
  justify-content: center;
}

.user-header-actions {
  display: flex;
  align-items: center;
}

.user-header-actions > * {
  margin-left: 22px;
}

.user-header-actions .user-header-but {
  font: 700 12px/1.3em "Titillium Web", sans-serif;
  position: relative;
  white-space: nowrap;
  border: none;
  height: 30px;
  padding: 0 20px;
  margin: 0 auto;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
}

.user-header-statistics {
  display: flex;
  font-size: 16px;
  flex-direction: row;
  padding: 15px 0;
  list-style: none;
}

.user-header-statistics li + li {
  margin-left: 40px;
}

.user-header-statistics a {
  color: inherit;
  display: flex;
  align-items: center;
}

.user-header-statistics a span {
  margin-left: 3px;
}

.user-header-contact a,
.user-header-statistics a,
.user-header-statistics a b {
  -webkit-transition: all ease-out 0.25s;
  -moz-transition: all ease-out 0.25s;
  -o-transition: all ease-out 0.25s;
  transition: all ease-out 0.25s;
}

.user-header-contact p,
.user-header-contact a {
  font-size: 13px;
  line-height: 1.5em;
}

.user-header-description {
  font-size: 13px;
  line-height: 1.4em;
  white-space: pre-line;
  margin-top: 5px;
}

.user-header-info h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .user-header-photo span {
    width: 76px !important;
    height: 76px !important;
    line-height: 78px !important;
  }

  .user-header .blocked .user-header-info {
    text-align: center;
  }

  .user-header .blocked .user-header-info h1 {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .user-header-contact p,
  .user-header-contact a {
    font-size: 14px;
    line-height: 1.3em;
  }

  .user-header.profile .brand img {
    width: 80px;
    height: 80px;
  }

  .user-header-contact > *:not(.job-description):first-child {
    margin-top: 3px;
  }

  .user-header-contact .job-description {
    margin-bottom: 15px;
  }

  .user-header-contact > *:last-child {
    margin-bottom: 10px;
  }

  .user-header {
    margin-top: 0;
    margin-bottom: 45px;
  }

  .user-header .blocked {
    display: flex;
    align-items: center;
  }

  .user-header-about {
    padding: 0;
    border-top: none;
  }

  .user-header h1 {
    font: 26px/1.2em "Titillium Web", sans-serif;
    margin: 0;
  }

  .user-header-data {
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .user-header-data .ant-btn-circle {
    display: flex;
  }

  .user-header-actions .user-header-but {
    font: 700 14px/1.3em "Titillium Web", sans-serif;
    height: 40px;
    min-width: 150px;
    margin-left: 22px;
  }

  .user-header-statistics {
    padding: 0;
    margin: 0 0 20px !important;
  }

  .user-header h2 {
    font: 700 16px/1.2em "Titillium Web", sans-serif;
  }

  .user-header-description {
    font-size: 14px;
    line-height: 1.5em;
  }
}
