body,
.site-bg-color{
    background-color: #FDFDFD;
}

button.follow-button:focus,
button.follow-button,
.site-bg-color-second{
    background-color: #F4F4F4;
}

.site-icon-color-second{
    color: #F4F4F4;
}


.additional-menu-modal .avatar-data a,
.additional-menu-popover .avatar-data a,
.additional-menu a.with-icon,
.additional-menu button,
.search-sort .ant-radio-button-wrapper,
.documents-sorting .ant-radio-button-wrapper:hover,
.documents-sorting .ant-radio-button-wrapper-checked:hover,
.documents-sorting .ant-radio-button-wrapper-checked,
.profile-tabs .ant-tabs-left-bar .ant-tabs-tab.ant-tabs-tab-active,
textarea,
input[type="text"],
textarea.ant-input,
input[type="text"].ant-input,
.ant-input[disabled],
.ant-select-selection__rendered,
h1, h2, h3, h4, h5, h6,
a.inner-link,
.site-bold-color,
a.site-title-color,
.site-title-color{
    color: #000000;
}

.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-today .ant-calendar-date,
.chats-block-header,
.chat-messages-header,
.chat-message:not(.my-message) .chat-message-content,
.chat-active-item-bg,
.bar-bg-color,
.ant-drawer-header,
.avatar .ant-avatar,
.card-preview-bg-color,
.modal-actions .ant-modal-header,
.button-bg-color:focus,
.button-bg-color,
button.follow-button:hover,
button.rounded-button.ant-btn-primary:hover,
button.rounded-button:focus,
button.rounded-button,
button.rounded-button:hover,
.ant-btn[disabled]:hover,
.ant-btn[disabled],
button.rounded-button.ant-btn-primary[disabled],
button.rounded-button[disabled],
.input-bg-color{
    background-color: #EDEDED;
}

body,
.ant-checkbox-wrapper,
.ant-notification-notice-message,
button.rounded-button,
.site-text-color{
    color: #333333;
}

.chat-message-action-modal .delete-action button:hover,
.chat-message-reply .message-text,
.profile-tabs .ant-tabs-left-bar .ant-tabs-tab,
.site-label-text-color,
.site-sub-text-color{
    color: #8A8A8A;
}

.card-preview-row .profile-tabs .ant-tabs-left-bar .ant-tabs-tab,
.card-preview-row .site-label-text-color,
.card-preview-row .site-sub-text-color {
    color: #A2A2A2;
}


button.ant-btn:focus,
a:focus{
    color: inherit;
}

a, a:hover,
.ant-calendar-today .ant-calendar-date,
.additional-menu-modal .avatar-data a:hover,
.additional-menu-popover .avatar-data a:hover,
.additional-menu a.with-icon:hover,
.additional-menu button:hover,
.chat-message-action-modal .delete-action button,
.chat-messages-header.online .avatar-text,
.chat-list-item .state,
.chat-message-status .state,
.chat-list-item-wrap.chat-active-item-bg .avatar-label,
.ant-radio-group .ant-radio-button-wrapper-checked,
.ant-radio-group .ant-radio-button-wrapper:hover,
button.rounded-button.ant-btn-primary:hover,
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active,
.card-attachments button,
.card-attachments button:focus,
button.ant-btn-primary.follow-button:hover,
.company-link-hover button.bold-text,
.company-icon-hover button:hover,
.company-icon-hover a:hover,
.company-icon-hover button.active,
.company-icon-hover a.active,
.company-link-hover button:hover,
.company-link-hover a:hover,
.company-link-hover a:hover b,
.company-link-hover button.active,
.company-link-hover a.active,
.company-icon-color .anticon,
.site-sub-link-color:hover,
.ant-avatar .anticon-loading,
.ant-spin,
.ant-btn:hover,
.ant-btn:hover:focus,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active,
.company-children-hover-color:hover > *,
.company-color{
    color: #AE823B;
}

.additional-menu li .game,
.ant-btn:focus{
    color: #AE823B !important;
}

html {
    --antd-wave-shadow-color: #AE823B;
}

.chat-list-item .not-read:before,
.chat-wrap .ant-avatar,
.chat-message-form button:hover,
.chat-message-form button,
.ant-radio-inner::after,
.ant-mentions-dropdown-menu-item-active .avatar:after,
.ant-mentions-dropdown-menu-item .avatar:after,
.ant-checkbox-checked .ant-checkbox-inner,
.add-comment button:hover,
.add-comment button,
.card-attachments button:focus:hover,
.card-attachments button:hover,
.invert-hover-bg-text:hover,
.card-media-slider .ant-carousel .slick-dots li.slick-active button,
.ant-spin-dot-item,
button.rounded-button.ant-btn-primary,
button.rounded-button.ant-btn-primary:focus,
button.ant-btn-primary,
button.ant-btn-primary:focus,
.notification-list .not-read:before,
.avatar-badge,
.card-post .badge,
.card-preview .badge,
.company-bg-color-child > *,
.company-bg-color{
    background-color: #AE823B;
}

.ant-btn-primary{
    text-shadow: none;
}

.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner,
.company-border-color{
    border-color: #AE823B;
    border-width: 1px;
}


.ant-btn,
.chat-message-reply .message,
.card-preview.news,
.card-post.news .card-media-slider-wrap,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
button.ant-btn-primary.follow-button:focus,
button.ant-btn-primary.follow-button,
.ant-btn:focus{
    border-color: #AE823B;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after{
    border-color: #AE823B !important;
}

.ant-input,
.ant-mentions,
.border-color-light{
    border-color: #DDDDDD;
}

.ant-calendar-today .ant-calendar-date,
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover,
.ant-upload.ant-upload-drag:hover,
.ant-upload.ant-upload-select-picture-card:hover,
.ant-select-selection--single:focus,
.ant-select-selection--single:hover,
.ant-mentions:focus,
.ant-mentions:hover,
.ant-input:focus,
.ant-input:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-input + .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner{
    border-color: #AE823B;
}

.ant-checkbox-input + .ant-checkbox-inner{
    border-color: #676767;
}

.ant-input:focus{
    box-shadow: none;
}

.ant-checkbox-inner{
    border-width: 2px;
}

.chat-message.my-message .chat-message-content,
.ant-upload.ant-upload-drag,
.ant-checkbox-inner{
    border-color: #A2A2A2;
}

.ant-avatar{
    border-color: #E4E4E4;
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
    background-color: transparent;
}

.chat-message-wrap.selected,
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.card-attachments button:focus,
.card-attachments button,
.company-bg-color-light{
    background-color :#F9EBD8;
}

.ant-calendar-date:hover,
button.rounded-button.ant-btn-primary:focus:hover,
.card-attachments button:focus:hover,
.card-attachments button:hover,
.invert-hover-bg-text:hover,
.company-color-light{
    color: #fef3f9;
}

button.rounded-button.ant-btn-primary,
button.rounded-button.ant-btn-primary:focus,
button.ant-btn-primary.follow-button,
button.ant-btn-primary.follow-button:focus,
.chat-message-form button:hover,
.add-comment button:hover,
.company-bg-color.with-font-color{
    color: #FFF;
}

.header-bg-color{
    background-color: #000000;
}

.ant-calendar-date:hover,
.card-media-slider .ant-carousel .slick-dots li button{
    background-color: #A2A2A2;
}

.posts-view-type .ant-radio-button-wrapper,
.ant-notification-notice-description,
textarea::placeholder,
input::placeholder,
textarea.ant-input::placeholder,
input.ant-input::placeholder,
.site-sub-text-light-color,
.site-sub-link-color{
    color: #A2A2A2;
}

.chats-block-header,
.top-app-bar-title,
.ant-drawer-header h4,
.posts-view-type .ant-radio-button-wrapper-checked:hover,
.posts-view-type .ant-radio-button-wrapper-checked,
.ant-tabs-tab,
.modal-actions .ant-modal-title h3,
.site-icon{
    color:  #676767;
}

.site-badge{
   background-color: #676767;
}

.chat-list-item .state.read,
.chat-message-status .state.read,
button.ant-btn[disabled]:hover,
button.ant-btn[disabled],
button.rounded-button.ant-btn-primary[disabled],
button.rounded-button[disabled],
.header-color button,
.header-color a{
    color: #A2A2A2;
}

.site-badge,
.header-color button.active,
.header-color a.active{
    color: #FFF;
}

button.rounded-button{
    border: none;
}
