header {
    display: none;
    background-color: #fff;
    box-shadow: 0 -5px 40px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #979797;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050 !important;
}

header > * {
    height: inherit;
}

header .ant-row-flex-middle {
    height: 100%;
}

.logo a {
    display: flex;
    align-items: center;
}

.header-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}


header .logo span{
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

header .logo span {
    height: 45px;
    width: 50px;
    overflow: hidden;
    display: inline-block;
}

header .logo span:first-child {
    width: 93px;
}

header .logo span:last-child {
    width: 88px;
}

.header-row .left-side .logo-img a {
    /* display: inline-block; */
}

.header-row .left-side .logo-img img {
    max-width: 100%;
    height: auto;
}

.header-row .notification-badge {
    left: 15px;
}

@media (min-width: 768px) {
    header {
        display: block;
        height: 70px;
    }
    header + .header-placeholder {
        height: 70px;
    }

    .header-row .left-side {
        min-width: 150px;
        max-width: 220px;
    }
}

@media (min-width: 1024px) {
    .header-row .left-side {
        min-width: 230px;
        max-width: 290px;
    }

    .header-row .notification-badge {
        left: 20px;
    }
}
