:root{
    --vh: 1px;
}

body,
html {
    height: initial;
}

body {
    font: 400 16px/1.5em 'Titillium Web', sans-serif;
    min-width: 320px;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

body.ant-scrolling-effect{
    overflow: hidden !important;
}

p {
    margin: 0;
}

form input[type="radio"],
form input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.ant-modal-header,
.ant-drawer-header {
    padding: 14px 12px;
}

.ant-modal-close-x{
    width: 51px;
    height: 51px;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-checkbox-inner {
    width: 18px;
    height: 18px;
}

.ant-checkbox-inner::after {
    left: 4px;
}

.ant-checkbox + span {
    padding-right: 13px;
    padding-left: 13px;
}

.main-block {
    overflow: hidden;
}

.block-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font: 16px/2em 'Titillium Web', sans-serif;
}

.block-loader .ant-spin-text {
    font: 16px/2em 'Titillium Web', sans-serif;
}

.item-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font: 16px/2em 'Titillium Web', sans-serif;
}

.item-loader {
    min-height: inherit;
}

.in-development {
    font-weight: bold;
    font-size: 12px;
    color: red;
}

.show-modal {
    overflow-y: scroll !important;
    position: fixed !important;
}

.modal-actions .ant-modal {
    min-width: auto !important;
    width: 100% !important;
    max-width: 500px !important;
}

.modal-actions .ant-modal-title h3 {
    font-weight: bold;
    text-align: center;
    margin: 0;
    font-size: 13px;
}

.modal-actions .ant-modal-content {
    border-radius: 0 !important;
    overflow: hidden;
}

.modal-actions .ant-modal-body {
    max-height: 400px;
    overflow: auto;
    padding: 0 !important;
}

.modal-actions .ant-modal-close-x .anticon {
    vertical-align: initial !important;
}

.modal-actions-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.modal-actions-list li + li {
    border-top: 1px solid #ededed;
}

.modal-actions-list li > .blocked,
.modal-actions-list li > button,
.modal-actions-list li > a {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1.5em;
    margin: 0;
    min-height: 48px;
    padding: 4px 8px;
    justify-content: center;
    width: 100%;
    border: none;
    color: inherit;
    background-color: transparent;
    outline: none;
}

.modal-actions-list li > .blocked{
    cursor: no-drop;
}

.modal-actions-list li > button.bold-text {
    font-weight: bold;
}

.modal-actions-list li:last-child button {
    background-color: #f4f4f4;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.modal-actions-list li:last-child button:hover {
    background-color: #dbdbdb;
}

.ant-notification,
.ant-message {
    z-index: 1080 !important;
}

.ant-modal-wrap,
.ant-modal-mask,
.ant-drawer {
    z-index: 1070 !important;
}

.inner-link {
    color: inherit;
    text-decoration: underline;
    font-weight: 700;
}

button.rounded-button {
    min-width: 130px;
    height: 30px;
    padding: 0 15px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    font-weight: bold;
    font-size: 12px;
}

button.small-rounded-button {
    font-size: 20px;
    border: none;
    line-height: 1em;
    box-shadow: none;
    background-color: transparent;
}

button.small-rounded-button:focus {
    background-color: transparent;
}

.site-badge {
    font-size: 12px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-upload .ant-avatar {
    display: flex;
    align-items: center;
}

.posts-view-type .ant-radio-group {
    border: 2px solid transparent;
    display: flex;
    border-radius: 20px;
}

.posts-view-type .ant-radio-button-wrapper {
    min-width: 150px;
    font-size: 24px;
    border: none;
    background-color: transparent;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.posts-view-type .ant-radio-button-wrapper {
    border-radius: inherit;
}

.posts-view-type .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.posts-view-type .ant-radio-button-wrapper-checked {
    box-shadow: none;
}

.posts-view-type .ant-radio-button-wrapper:hover::before,
.posts-view-type .ant-radio-button-wrapper:not(:first-child)::before,
.posts-view-type .ant-radio-button-wrapper-checked::before {
    display: none;
    box-shadow: none;
}

.posts-view-type .ant-radio-button-wrapper:focus-within {
    outline: none;
}

.posts-view-type .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background-color: #fff;
}

.back-button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 50%;
    position: fixed;
    left: 30px;
    top: 130px;
    transition: all ease-in-out 0.25s;
}

.ant-drawer-header h4 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
}

.ant-drawer-title {
    position: relative;
}

.ant-drawer-title .close-drawer {
    font-size: 12px;
    border: none;
    background-color: transparent;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.outer-link:hover {
    text-decoration: underline;
}

.ant-result-extra {
    display: flex;
    justify-content: center;
}

.site-label-text-color {
    font-size: 11px;
}

.ant-select {
    font: 13px/1em 'Titillium Web', sans-serif;
    height: 32px;
}

.ant-select-dropdown {
    font: 13px/1em 'Titillium Web', sans-serif;
}

.ant-mentions textarea,
.ant-input {
    font: 13px/1.3em 'Titillium Web', sans-serif;
    padding: 8px;
}

.ant-input{
    height: 32px;
}

.collection-update .form-actions,
.post-update-details .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 19px;
}

.post-update-details .form-actions > div {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.collection-header,
.hashtag-header {
    margin-top: 12px;
    border-bottom: 1px solid #e9ebee;
}

.collection-header h1,
.hashtag-header h1 {
    font: 400 16px/1.2em 'Titillium Web', sans-serif;
    margin: 0;
}

.collection-header-data,
.hashtag-header-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.collection-header-photo,
.hashtag-header-photo {
    display: flex;
    justify-content: center;
}

.collection-header-actions .collection-header-but,
.hashtag-header-actions .hashtag-header-but {
    font: bold 12px/1.3em 'Titillium Web', sans-serif;
    white-space: nowrap;
    border: none;
    height: 30px;
    min-height: auto;
    padding: 0 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    position: relative;
}

.collection-header-actions,
.hashtag-header-actions {
    display: flex;
    align-items: center;
}

.collection-header-author,
.collection-header-status,
.hashtag-header-author,
.hashtag-header-status {
    display: flex;
    justify-content: center;
    margin: 10px 0 0;
}

.user-header-statistics,
.collection-header-statistics,
.hashtag-header-statistics {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    flex-direction: row;
    margin: 0;
    padding: 13px 0;
    list-style: none;
}

.user-header-statistics li + li,
.collection-header-statistics li + li,
.hashtag-header-statistics li + li {
    margin-left: 58px;
}

.user-header-statistics .site-bold-color,
.collection-header-statistics .site-bold-color,
.hashtag-header-statistics .site-bold-color {
    display: block;
    font-size: 14px;
}

.collection-posts-actions,
.hashtag-posts-actions {
    padding: 10px 0;
    border-bottom: 1px solid #e9ebee;
}

.collection-posts-actions .posts-view-type,
.hashtag-posts-actions .posts-view-type {
    max-width: 304px;
    margin: 0 auto;
}

.no-content-icon .anticon{
    font-size: 152px;
}

.no-content-icon{
    margin-bottom: 10px;
}

.grid-two-col{
    grid-row-gap: 0;
    grid-column-gap: 20px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2,1fr);
}

/* media query */

@media (max-width: 467px) {
    .grid-two-col{
        grid-template-columns: repeat(1,1fr);
    }
}

@media (max-width: 767px) {
    .modal-actions .ant-modal {
        vertical-align: bottom;
        max-width: 100% !important;
        padding: 0;
        margin: 0;
    }

    .modal-actions .ant-modal-body {
        max-height: calc(100vh - 51px);
    }

    .ant-modal-body button.rounded-button {
        min-width: 100px;
    }

    .ant-form-item-label {
        padding: 0 !important;
        margin: 0 0 2px !important;
    }

    .ant-form-item-control {
        line-height: initial;
    }

    .ant-form-item-control textarea {
        min-height: 82px !important;
        height: 82px !important;
    }

    .ant-form-item {
        margin-bottom: 7px;
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 9px 0;
    }

    .collection-header-data h1,
    .hashtag-header-data h1 {
        padding: 15px 0 11px;
    }

    .collection-header-author .site-label-text-color,
    .collection-header-status .site-label-text-color,
    .hashtag-header-author .site-label-text-color,
    .hashtag-header-status .site-label-text-color {
        font-size: 12px;
    }
}

.mobile-logo {
    display: flex;
    justify-content: center;
    padding-top: 14px;
    position: absolute;
    z-index: 4;
    background-color: transparent;
    left: 50%;
    transform: translate(-50%, 0px);
}

.mobile-logo-wrap {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    list-style: none;
    margin: 0;
}

.mobile-logo-wrap img{
    min-width: 150px;
    max-width: 240px;
}

.mobile-logo-wrap li{
    display: flex;
    align-items: center;
}

.mobile-logo-wrap .notification-button{
    color: #FFF;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
    font-size: 28px;
}

.diamond-status {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: #AE823B;
}

.diamond-status .anticon {
    display: block;
    margin-left: 6px;
    font-size: 16px;
}

@media (min-width: 768px) {
    body {
        margin-bottom: 0;
    }

    button.rounded-button {
        font-size: 14px;
        height: 40px;
        padding: 0 20px;
    }

    .site-badge {
        font-size: 18px;
        width: 34px;
        height: 34px;
    }

    .ant-modal-close-x{
        width: 56px;
        height: 56px;
    }

    .site-label-text-color {
        font-size: 13px;
    }

    .ant-select {
        font: 14px/1em 'Titillium Web', sans-serif;
        height: 40px;
    }

    .ant-select-dropdown {
        font: 14px/1em 'Titillium Web', sans-serif;
    }

    .ant-mentions textarea,
    .ant-input {
        font: 14px/1.5em 'Titillium Web', sans-serif;
        padding: 10px 15px;
    }

    .ant-input{
        height: 40px;
    }

    .ant-drawer-header h4 {
        font-size: 14px;
    }

    .collection-update .form-actions,
    .post-update-details .form-actions {
        margin-top: 25px;
    }

    .post-update-details .form-actions > div {
        display: flex;
        justify-content: flex-start;
        width: auto;
    }

    .post-update-details .form-actions div:first-child {
        display: block;
    }

    .posts-view-type .ant-radio-button-wrapper {
        min-width: 100px;
        height: 36px;
    }

    .collection-header-actions .collection-header-but,
    .hashtag-header-actions .hashtag-header-but {
        font: bold 14px/1.3em 'Titillium Web', sans-serif;
        padding: 0 20px;
        min-width: 150px;
        height: 40px;
    }

    .collection-header,
    .hashtag-header {
        margin-top: 0;
        padding-bottom: 45px;
        min-height: 150px;
    }

    .collection-header h1,
    .hashtag-header h1 {
        font: 400 24px/1.2em 'Titillium Web', sans-serif;
    }

    .collection-header-data,
    .hashtag-header-data {
        flex-direction: row;
        margin-bottom: 20px;
    }

    .collection-header-actions > *,
    .hashtag-header-actions > * {
        margin-left: 15px;
    }

    .user-header-statistics,
    .collection-header-statistics,
    .hashtag-header-statistics {
        text-align: left;
        font-size: 14px;
        justify-content: flex-start;
        padding: 0;
    }

    .user-header-statistics .site-bold-color,
    .collection-header-statistics .site-bold-color,
    .hashtag-header-statistics .site-bold-color {
        font-size: 16px;
        display: inline;
    }

    .site-bold-color li + li,
    .collection-header-statistics li + li,
    .hashtag-header-statistics li + li {
        margin-left: 40px;
    }

    .collection-header-author,
    .collection-header-status,
    .hashtag-header-author,
    .hashtag-header-status {
        display: block;
        margin: 5px 0 20px;
    }

    .collection-posts-actions .posts-view-type,
    .hashtag-posts-actions .posts-view-type {
        max-width: 100%;
        margin: 0;
        display: flex;
    }

    .collection-posts-actions,
    .hashtag-posts-actions {
        border-bottom: none;
    }

    .modal-actions .ant-modal-title h3 {
        font-size: 16px;
    }

    .modal-actions .ant-modal-content {
        border-radius: 11px !important;
        overflow: hidden;
    }

    .user-header-about .diamond-status {
        font-size: 18px;
    }

    .diamond-status {
        font-size: 15px;
        font-weight: 700;
        color: #AE823B;
    }

    .diamond-status .anticon {
        display: block;
        font-size: 22px;
    }
}
